<template>
	<Step
		:title="stepTitle"
		showNext
		@goNext="goNext"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in close2Options"
				:key="i"
				:column="3"
				value="name"
				:onchange="saveClose2"
				:item="item"
				multiple
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Close2',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		property: {}
	},
	data() {
		return {
			dubaiOptions: [
				{imgsrc: 'amenitiesPic/dubai_close2beach.jpeg', label: this.$t('onboarding-close2-beach'), datakey: 'onboarding-close2-beach', name: 'beach'},
				{imgsrc: 'amenitiesPic/dubai_close2sea.jpeg', label: this.$t('onboarding-close2-sea'), datakey: 'onboarding-close2-sea', name: 'sea'},
				{imgsrc: 'amenitiesPic/dubai_close2desert.jpeg', label: this.$t('onboarding-close2-desert'), datakey: 'onboarding-close2-desert', name: 'desert'},
				{imgsrc: 'amenitiesPic/dubai_close2dining.jpeg', label: this.$t('onboarding-close2-amenities'), datakey: 'onboarding-close2-amenities', name: 'dining'},
				{imgsrc: 'amenitiesPic/dubai_close2mall.jpeg', label: this.$t('onboarding-close2-mall'), datakey: 'onboarding-close2-mall', name: 'mall'}
			],
			commonOptions: [
				{imgsrc: 'onboarding/img-04.png', label: this.$t('onboarding-close2-golf'), datakey: 'onboarding-close2-golf', name: 'golf'},
				{imgsrc: 'onboarding/img-05.jpg', label: this.$t('onboarding-close2-beach'), datakey: 'onboarding-close2-beach', name: 'beach'},
				{imgsrc: 'onboarding/img-06.jpg', label: this.$t('onboarding-close2-amenities'), datakey: 'onboarding-close2-amenities', name: 'dining'},
				{imgsrc: 'onboarding/img-07.jpg', label: this.$t('onboarding-close2-nature'), datakey: 'onboarding-close2-nature', name: 'nature'},
				{imgsrc: 'onboarding/img-08.jpg', label: this.$t('onboarding-close2-sea'), datakey: 'onboarding-close2-sea', name: 'sea'}
			],
			selectedValue: []
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)[0]
		},
		close2Options() {
			if(this.countryLocation && this.countryLocation.includes('Dubai')) {
				return this.dubaiOptions
			} else {
				return this.commonOptions
			}
		},
		stepTitle() {
			if(this.profile.profile_type == 1) {
				return this.$t('onboarding-close2-buyer-title')
			} else {
				return this.$t('onboarding-close2-title')
			}
		}
	},
	methods: {
		getImg(el) {
			console.log(this.profile.tags)
			let areaLocation = this.profile.tags
			.find(item => item.type == 7 && ((item.name.match(/ > /g) || []).length == 1 || (item.name.match(/ > /g) || []).length == 2))['name']
			areaLocation = areaLocation.includes('Spain') ? areaLocation.substring(areaLocation.indexOf('>') + 1, areaLocation.lastIndexOf('>')).trim().toLowerCase() : areaLocation.substring(areaLocation.lastIndexOf('>') + 1).trim().toLowerCase()

			try {
				return require(`@/assets/img/amenitiesPic/${areaLocation}_close2${el.name}.jpeg`)
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		saveClose2(e) {
			let close2 = e.target.value

			if(this.selectedValue.includes(close2)) {
				this.selectedValue = this.selectedValue.filter(item => item != close2)
			} else {
				this.selectedValue.push(close2)
			}
			this.$emit('saveProfileInfo', 'close2', this.selectedValue)
		},
	},
	mounted() {
		if(this.profile[this.property] && this.profile[this.property].length > 0) {
			this.selectedValue = this.profile[this.property].split('|').filter(item => item != '')
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>