<template>
	<Step
		:title="stepTitle"
	>
		<div class="text-center">
			<p>{{ $t('onboarding-price-subtitle') }}</p>
		</div>
		<div class="custom-row budget-row" v-if="profile">
			<StepCard
				v-for="(item, i) in options"
				:key="i"
				:column="10"
				value="value"
				:onchange="selectPrice"
				:item="item"
				:selectedValue="selectedValue"
			>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Budget',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		property: {}
	},
	data() {
		return {
			dubaiOptions: [
				//maybe value should be different?
				{value: 100000, label: '< 1 000 000'},
				{value: 1000000, label: '1 000 000 - 1 250 000'},
				{value: 1250000, label: '1 250 000 - 1 500 000'},
				{value: 1500000, label: '1 500 000 - 2 000 000'},
				{value: 2000000, label: '2 000 000 - 3 000 000'},
				{value: 3000000, label: '3 000 000 - 4 000 000'},
				{value: 4000000, label: '4 000 000 - 6 000 000'},
				{value: 6000000, label: '6 000 000 - 8 000 000'},
				{value: 8000000, label: '8 000 000 - 12 000 000'},
				{value: 120000000, label: '> 12 000 000'},
			],
			commonOptions: [
				//maybe value should be different?
				{value: 50000, label: '< 200 000'},
				{value: 200000, label: '200 000 - 300 000'},
				{value: 300000, label: '300 000 - 400 000'},
				{value: 400000, label: '400 000 - 500 000'},
				{value: 500000, label: '500 000 - 750 000'},
				{value: 750000, label: '750 000 - 1 000 000'},
				{value: 1000000, label: '1 000 000 - 1 500 000'},
				{value: 1500000, label: '1 500 000 - 2 000 000'},
				{value: 2000000, label: '2 000 000 - 3 000 000'},
				{value: 10000000, label: '> 3 000 000'},
			],
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)[0]
		},
		currency() {
			return this.countryLocation.includes('Dubai') ? 'AED' : 'EUR'
		},
		options() {
			if(this.countryLocation.includes('Dubai')) {
				return this.dubaiOptions
			} else {
				return this.commonOptions
			}
		},
		stepTitle() {
			if(this.profile.profile_type == 1) {
				return `${this.$t('onboarding-price-buyer-title')} ${this.currency}`
			} else if(this.profile.profile_type == 3) {
				return `${this.$t('onboarding-price-dev-title')} ${this.currency}`
			} else {
				return `${this.$t('onboarding-price-title')} ${this.currency}`
			}
		}
	},
	methods: {
		getImg(el) {
			let areaLocation = this.countryLocation.substring(this.countryLocation.lastIndexOf('>') + 1).trim().toLowerCase()
			
			try {
				return require(`@/assets/img/onboarding/${areaLocation}_${el.name}.jpeg`)
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		selectPrice(e) {
			this.$emit('saveProfileInfo', 'price', e.target.value)
		}
	},
	mounted() {
		if(this.profile[this.property]) {
			this.selectedValue = this.profile[this.property];
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
.budget-row {
	.fake-label {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>