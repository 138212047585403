<template>
	<Step
		:title="stepTitle"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in styleOptions"
				:key="i"
				:column="3"
				value="name"
				:onchange="selectAndGoNext"
				:item="item"
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
			<StepCard
				:column="3"
				value="name"
				:onchange="selectAndGoNext"
				:item="dkOption"
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(dkOption)" alt="">
				</div>
				<strong class="fake-title" >{{ dkOption.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Style',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		goNext: Function,
		property: {}
	},
	data() {
		return {
			dubaiOptions: [
				{imgsrc: 'dubai_modern.jpeg', label: this.$t('onboarding-style-new'), datakey: 'onboarding-style-new', name: 'new'},
				{imgsrc: 'dubai_traditional.jpeg', label: this.$t('onboarding-style-traditional'), datakey: 'onboarding-style-traditional', name: 'old'}
			],
			commonOptions: [
				{imgsrc: 'img-01.jpg', label: this.$t('onboarding-style-new'), datakey: 'onboarding-style-new', name: 'new'},
				{imgsrc: 'img-02.jpg', label: this.$t('onboarding-style-traditional-and'), datakey: 'onboarding-style-traditional-and', name: 'old'}
			],
			dkOption: {
				imgsrc: null,
				label: this.$t('onboarding-style-dk'),
				datakey: 'onboarding-style-dk',
				name: 'not_answered'
			},
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)[0]
		},
		styleOptions() {
			if(this.countryLocation.includes('Dubai')) {
				return this.dubaiOptions
			} else {
				return this.commonOptions
			}
		},
		stepTitle() {
			if(this.profile.profile_type == 1) {
				return this.$t('onboarding-style-buyer-title')
			} else if(this.profile.profile_type == 3) {
				return this.$t('onboarding-style-dev-title')
			} else {
				return this.$t('onboarding-style-title')
			}
		}
	},
	methods: {
		getImg(el) {
			try {
				return require(`@/assets/img/onboarding/${el.imgsrc}`)
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		selectAndGoNext(e) {
			this.$emit('saveProfileInfo', 'styles', e.target.value)
			this.$emit('goNext');
		}
	},
	mounted() {
		if(this.profile[this.property] && this.step < this.maxStep) {
			this.selectedValue = this.profile[this.property]
		}
	}
}
</script>

<style lang="scss">
</style>