<template>
	<Step
		v-if="profile"
		:title="`${$t('onboarding-location-in')} ${locationTagNameFormat(mainLocation)} ${$t('onboarding-location-interested-in')}?`"
	>
		<div class="custom-row">
			<StepCard
				v-for="(item, i) in options"
				:key="i"
				:item="item"
				:onchange="goNext"
				:column="3"
				value="action"
			>
				<div  class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.label }}</strong>
				<span class="fake-subtitle">{{ item.subtitle }}</span>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import { locationCountries } from '@/static/staticData.js'

export default {
	name: 'Location',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			locations: locationCountries,
			location: null,
			options: [
				{label: 'Show All Areas', action: 'go-next'},
				{label: this.$t('onboarding-type-dk'), action: 'skip-next'},
				//{label: 'Other', action: 'other'},
			]
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		mainLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)
			.find(item => item.split(' > ').length >= 2)
		}
	},
	methods: {
		getImg(el) {
			if(el.action == 'go-next') {
				return require(`@/assets/img/locations/${this.locationTagNameFormat(this.mainLocation).toLowerCase()}.jpg`);
			} else {
				return require('@/assets/img/default.jpg');
			}
		},
		goNext(e) {
			if(e.target.value == 'go-next') {
				this.$emit('goNext');
			} else if(e.target.value == 'skip-next') {
				this.$emit('skipNext');
			}
		},
	},
	mounted() {
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>