import axios from 'axios'

const mainStore = (app) => {
	return {
		state: {
			token: null,
			user_id: null,
			messages: null,
			profile: null,
			userCountry: '',
		},
		getters: {
			hasToken: s => !!s.token,
			token: s => s.token,
			user_id: s => s.user_id,
			messages: s => s.messages,
			profile: s => s.profile,
			userCountry: s => s.userCountry,
		},
		mutations: {
			setToken(state, token) {
				state.token = token;
				localStorage.setItem('token', token);
			},
			setUserID(state, user_id) {
				state.user_id = user_id;
				localStorage.setItem('user_id', user_id);
			},
			clearToken(state) {
				state.token = null;
				localStorage.removeItem('token');
			},
			setMessages(state, msgs) {
				state.messages = msgs
			},
			setProfileInfo(state, info) {
				state.profile = info
		
				if(state.profile) {
					localStorage.setItem('profileCompleted', state.profile.completed)
				}
			},
			setProfileItemInfo(state, item) {
				state.profile[item.property] = item.value
			},
			addProfileTagInfo(state, item) {
				state.profile['tags'].push(item)
			},
			removeProfileTagInfo(state, i) {
				state.profile['tags'].splice(i, 1);
			},
			resetProfileTagInfo(state) {
				state.profile['tags'] = state.profile['tags'].filter(item => item.type != 7);
			},
			setCountry(state, data) {
				state.userCountry = data
			},
		},
		actions: {
			login({ commit }, token) {
				commit('setToken', token.id);
				commit('setUserID', token.user_id);
				app.config.globalProperties.$api.defaults.headers.common['Authorization'] = `Bearer ${token.id}`
			},
			logout({ commit }) {
				commit('clearToken')
				commit('setProfileInfo', null)
				localStorage.removeItem('contactInfo');
				localStorage.removeItem('user_id');
			},
			setProfileItemInfo({ commit }, item) {
				commit('setProfileItemInfo', item)
			},
			addProfileTagInfo({ commit }, item) {
				commit('addProfileTagInfo', item)
			},
			removeProfileTagInfo({ commit }, i) {
				commit('removeProfileTagInfo', i)
			},
			resetProfileTagInfo({ commit }) {
				commit('resetProfileTagInfo')
			},
			saveProfile({ commit }, info) {
				commit('setProfileInfo', info);
				//if(info['completed']) commit('saveAllContactInfo', info);
			},
			async fetchProfile(context, user_id) {
				let id = user_id || localStorage.getItem('user_id')
				let baseURL = process.env.VUE_APP_API_URL || 'https://api1.newbuildproperties.com'
				return await axios.get(`${baseURL}/2/user_me`, {headers: {Authorization: `Bearer ${context.state.token}`}})
			},
			setCountry({ commit }, data) {
				commit('setCountry', data);
			},
		}
	}
}

export default mainStore