<template>
	<Step
		:title="$t('onboarding-our-location-title')"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(loc, i) in locations"
				:key="`location-${i}`"
				:item="loc"
				:onchange="selectLocationAndGoNext"
				:column="3"
				value="tag"
			>
				<div  class="img-holder">
					<img :src="getImg(loc)" alt="">
				</div>
				<strong class="fake-title" >{{ loc.title }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import { mainLocations } from '@/static/staticData.js'

export default {
	name: 'MainLocation',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		stepMode: {
			type: String,
			default: 'next'
		}
	},
	data() {
		return {
			locations: mainLocations,
			location: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)[0]
		}
	},
	methods: {
		selectLocation(e) {
			this.$emit('saveProfileLocationTags', e.target.value);
		},
		selectLocationAndGoNext(e) {
			this.$emit('saveProfileLocationTags', e.target.value);
			this.$emit('goNext');
		},
		getImg(el) {
			try {
				return require(`@/assets/img/locations/${el.title.toLowerCase()}.jpg`);
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		skipStep() {
			if(this.stepMode == 'back') {
				this.$emit('goBack');
			} else {
				this.$emit('goNext');
			}
		},
	},
	mounted() {
		if(this.profile && this.countryLocation && this.countryLocation.includes('Dubai')) {
			this.skipStep();
		}
	}
}
</script>

<style lang="scss">
</style>