<template>
	<div :class="[messagesLoading ? 'no-labels' : '', 'app']">
		<router-view></router-view>
		<div v-if="staging" class="alert alert-danger staging-alert">
			<p>Staging environment!</p>
		</div>
	</div>
</template>

<script>
import countries from '@/static/countries.json'

export default {
	name: 'App',
	data() {
		return {
			messagesLoading: true
		}
	},
	computed: {
		staging() {
			if(window.location.hostname == 'localhost' || 
			window.location.hostname == 'my2.newbuildproperties.com' ||
			window.location.hostname == 'my2.developments.es' ) return true;
			else return false;
		},
		profile() {
			return this.$store.getters.profile;
		},
		token() {
			return this.$store.getters.token;
		}
	},
	methods: {
		setupProfile() {
			let urlParams = new URLSearchParams(window.location.search);
			let token = localStorage.getItem('token') || urlParams.get('token');
			let user_id = localStorage.getItem('user_id') || urlParams.get('user_id');
			if(token && user_id && !this.profile) {
				console.log('login')
				this.$store.dispatch('login', {id: token, user_id: user_id})
				this.$nextTick()
				this.$store.dispatch('fetchProfile', user_id)
				.then(res => {
					if(res.data.msg == 'ok') {
						this.$store.dispatch('saveProfile', res.data);
						this.$nextTick()
					} else {
						this.$store.dispatch('logout');
					}

					if(res.data.msg == 'ok' && !res.data.completed) {
						this.$router.push({ name: 'onboarding' });
					}
				})
			}
		},
		async fetchUserCountry() {
			try {
				const response = await this.$axios.get('https://ipinfo.io/json')

				if(response.status === 200) {
					let userCountry = countries.countries.find(c => c.short == response.data.country)['name']
					this.$store.dispatch('setCountry', userCountry);
				} else {
					let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
					let tzArr = userTimeZone.split("/");
					let userCity = tzArr[tzArr.length - 1];
					this.$store.dispatch('setCountry', timeZoneCityToCountry[userCity]);
				}
			} catch(e) {
				let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				let tzArr = userTimeZone.split("/");
				let userCity = tzArr[tzArr.length - 1];
				this.$store.dispatch('setCountry', timeZoneCityToCountry[userCity]);
			}
		},
	},
	watch: {
		token() {
			if(this.token && !this.profile) {
				//this.setupProfile();
			}
		}
	},
	created() {
		this.setupTranslations();

		localStorage.setItem('lastVisited', new Date())
	},
	async mounted() {
		this.setupProfile();

		await this.fetchUserCountry()
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/icomoon.scss";

.staging-alert {
	position: fixed;
	bottom: 20px;
	left: 20px;
	pointer-events: none;
	z-index: 9999;
	opacity: 0.8;
	font-size: 26px;
	color: #fff;
	background-color: #b82633;
	border-color: #b82633;
	padding: 7px 10px;
	border-radius: 5px;

	p {
		margin-bottom: 0;
	}
}
</style>
