<template>
	<Step
		:title="$t('onboarding-company-title')"
		showNext
		@goNext="saveAndGoNext"
	>
		<div class="custom-row" v-if="profile">
			<div class="col-6">
				<label class="label">
					<input
						type="text"
						v-model="fullName"
						maxlength="30"
						class="bold-input"
						autofocus
					>
				</label>
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';

export default {
	name: 'CompanyName',
	components: {
		Step
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			fullName: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		}
	},
	methods: {
		saveAndGoNext(e) {
			this.$emit('saveProfileInfo', 'company', this.fullName)
		}
	},
	mounted() {
		if(this.profile.company) {
			this.fullName = this.profile.company;
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>