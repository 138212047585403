<template>
	<Step
		:title="stepTitle"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in popularLanguages"
				:key="i"
				:item="item"
				:onchange="saveSelection"
				:column="7"
				value="code"
				:selectedValue="selectedValue"
			>
				<div class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.name }}</strong>
			</StepCard>
		</div>
		<div class="text-center">
			<p>Or do you speak another language</p>
		</div>
		<SelectBox
			:options="languages"
			@saveDropSelection="saveDropSelection"
		/>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import SelectBox from '@/components/Common/SelectBox.vue';
import { languages } from '@/static/staticData.js'

export default {
	name: 'Language',
	components: {
		Step,
		StepCard,
		SelectBox
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			languages: languages,
			preferedLanguages: {
				'us': ['gb'],
				'gb': ['gb'],
				'se': ['se'],
				'dk': ['dk'],
				'be': ['fr', 'nl'],
				'nl': ['nl'],
				'es': ['es'],
				'fr': ['fr'],
				'de': ['de'],
				'ie': ['gb', 'ga'],
				'pl': ['pl'],
				'no': ['no'],
				'ae': ['ar']
			},
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		popularLanguages() {
			let popularLanguages = [ 'gb', 'nl', 'fr', 'se', 'dk', 'es', 'de', 'pl', 'no', 'ar' ];
			let selectedLanguages = []
			console.log(this.profile.country)
			let country = this.profile.country ? this.profile.country.toLowerCase() : ''

			if(this.preferedLanguages[country]) {
				selectedLanguages = this.languages.filter(item => {
					return this.preferedLanguages[country].indexOf(item.code) >= 0
				})
			}
			if(selectedLanguages.length == 0) {
				selectedLanguages = this.languages.filter(item => {
					return popularLanguages.indexOf(item.code) >= 0
				})
			}

			return selectedLanguages
		},
		stepTitle() {
			if(this.profile.profile_type == 2 || this.profile.profile_type == 4) {
				return this.$t('onboarding-language-agent-title')
			} else {
				return this.$t('onboarding-language-title')
			}
		}
	},
	methods: {
		saveSelection(e) {
			this.$emit('saveProfileInfo', 'languages', [e.target.value]);
			this.$emit('goNext')
		},
		saveDropSelection(loc) {
			this.$emit('saveProfileInfo', 'languages', [loc.code]);
			this.$emit('goNext')
		},
		getImg(item) {
			try {
				return require(`@/assets/img/flags/${item.short.toLowerCase()}.svg`)
			} catch(e) {
				return require(`@/assets/img/default.jpg`);
			}
		},
	},
	mounted() {
		if(this.profile.languages) {
			this.selectedValue = this.profile.languages.split('|').filter(item => item != '')
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>