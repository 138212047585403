<template>
	<Step
		:title="$t('onboarding-invest-title')"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in investOptions"
				:key="i"
				:column="3"
				value="name"
				:onchange="saveInvestAndGoNext"
				:item="item"
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Investements',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		goNext: Function,
		property: {}
	},
	data() {
		return {
			investOptions: [
				{imgsrc: 'img-10', label: this.$t('onboarding-invest-payment_plan'), datakey: 'onboarding-invest-payment_plan', name: 'payment_plan'},
				{imgsrc: 'img-11', label: this.$t('onboarding-invest-rental_roi'), datakey: 'onboarding-invest-rental_roi', name: 'rental_roi'},
				{imgsrc: 'img-12', label: this.$t('onboarding-invest-value_roi'), datakey: 'onboarding-invest-value_roi', name: 'value_roi'},
				{imgsrc: 'img-13', label: this.$t('onboarding-invest-established'), datakey: 'onboarding-invest-established', name: 'established'}
			],
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.type == 7)
			.map(item => item.name ? item.name : item)[0]
		},
	},
	methods: {
		getImg(el) {
			try {
				return require(`@/assets/img/amenitiesPic/${el.name}.png`)
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		saveInvestAndGoNext(e) {
			this.$emit('saveProfileInfo', 'invest', e.target.value);
			this.$emit('goNext');
		},
		skipStep() {
			if(this.stepMode == 'back') {
				this.$emit('goBack');
			} else {
				this.$emit('goNext');
			}
		},
	},
	mounted() {
		if(this.profile && this.countryLocation && this.countryLocation.includes('Spain')) {
			this.skipStep();
		}

		if(this.profile[this.property] && this.profile[this.property].length > 0) {
			this.selectedValue = this.profile[this.property].split('|').filter(item => item != '')
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>