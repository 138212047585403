<template>
	<main class="login-view">
		<Modal
			:title="modalTitle"
			hideClose
			hideBackdrop
			:size="signupStep == 2 ? 'lg' : 'md'"
			ref="login-modal"
		>
			<TransitionGroup name="slide2">
			<!-- Email step -->
			<form action="#" @submit.prevent="submitEmail" v-if="signupStep == 0">
				<label class="label">
					<span class="fake-label">{{ $t('header-signup-email') }}</span>
					<input type="email" class="input" v-model="email" ref="focusEmail" name="email" autofocus>
				</label>
				<label v-if="mode == 'signup'" class="label custom-check mb-2">
					<input type="checkbox" name="communication" v-model="receiveCommunication">
					<span class="fake-input"></span>
					<span class="fake-label">{{ $t('header-agree') }} newbuilldproperties.com</span>
				</label>
				<button type="submit" class="btn btn-primary w-100 mb-3">{{ $t('common-signup-submit') }}</button>
			</form>
			<!-- Password step -->
			<form action="#" @submit.prevent="submitPass" v-if="signupStep == 1">
				<label class="label">
					<span class="fake-label">{{ $t('header-signup-password') }}</span>
					<input type="password" class="input" v-model="password" ref="focusPass" name="password" autofocus>
				</label>
				<button type="submit" class="btn btn-primary w-100 mb-3">{{ $t('common-signup-next') }}</button>
			</form>
			<!-- Account type step -->
			<form
				action="#"
				@submit.prevent=""
				v-if="signupStep == 2"
				:class="{ 'loading': loading }"
			>
				<Step
					title="What describes you the best?"
				>
					<div class="custom-row">
						<StepCard
							v-for="(item, i) in accountTypes"
							:key="i"
							:item="item"
							:onchange="submitType"
							value="id"
						>
							<div  class="icon-holder">
								<i :class="`icon-${item.label}`"></i>
							</div>
							<strong class="fake-title" >{{ item.label }}</strong>
						</StepCard>
					</div>
				</Step>
			</form>
			</TransitionGroup>
			<template v-if="signupStep < 2" v-slot:footer>
				<p v-if="mode == 'signup'">Already have an account? <a href="#" @click.prevent="switchMode('login')">Login here</a></p>
				<p v-else>Don't have an account? <a href="#" @click.prevent="switchMode('signup')">Sign up</a></p>
			</template>
		</Modal>
	</main>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

// TODO ensure when chrome or firefox saves passwords that they're saving the email + password //TEST THIS ON STAGE OR PROD

export default {
	name: 'LoginView',
	components: {
		Modal,
		Step,
		StepCard
	},
	data() {
		return {
			accountTypes: [
				{ id: 1, label: 'buyer' },
				{ id: 3, label: 'developer' },
				{ id: 4, label: 'agent' },
				{ id: 5, label: 'seller' }
			],
			mode: 'signup',
			email: null,
			password: null,
			receiveCommunication: true,
			signupStep: 0,
			accountType: null,
			loading: false
		}
	},
	computed: {
		modalTitle() {
			return this.mode == 'signup' ? 'Free Signup' : 'Login';
		}
	},
	methods: {
		submitEmail() {
			if(!this.receiveCommunication) {
				this.$store.dispatch('showInfoFlash', {msg: this.$t('header-signup-receive-communication'), delay: 7000});
				return;
			}
			this.signupStep++;

			this.$nextTick(() => {
				this.$refs.focusPass.focus();
			});
		},
		submitPass() {
			this.signupStep++;
		},
		submitType(e) {
			this.accountType = e.target.value;
			this.signup()
		},
		async signup() {
			this.$store.dispatch('logout')
			this.loading = true;

			let data = {
				email: this.email.toLowerCase(),
				password: this.password,
				type: this.accountType,
				domain: 'nbp' // TODO
			};

			try {
				const response = await this.$api.post('/user/signup', data);

				if(response.data.msg == 'ok') {
					this.$store.dispatch('login', response.data.token);
					this.$store.dispatch('fetchProfile', response.data.token.user_id)
					.then(res => {
						if(res.data.msg == "Invalid access. Requires user") {
							this.$store.dispatch('logout');
						} else {
							this.$store.dispatch('saveProfile', res.data);
							this.$store.dispatch('showSuccessFlash', {msg: this.$t('header-signup-welcome')});
							setTimeout(() => {
								this.$router.push({ name: 'onboarding' });
							}, 100);
						}
					})
					this.loading = false;
				} else {
					this.$store.dispatch('showErrorFlash', {msg: response.data.msg});
					this.loading = false;
				}
			} catch(e) {
				console.log(e)
				this.$store.dispatch('showErrorFlash', {msg: response.data.msg});
				this.loading = false;
			}
		},
		focusInput() {
			this.$refs.focusEmail.focus();
		},
		switchMode(mode) {
			this.mode = mode;
			this.$nextTick(this.focusInput);
		}
	},
	mounted() {
		this.$refs['login-modal'].openModal()
		
		this.$nextTick(this.focusInput);
	}
}
</script>

<style scoped lang="scss">
.login-view {
	background-color: #818181;
}
</style>
