<template>
	<main class="onboarding-view" v-if="profile">
		<div class="step-nav">
			<a v-if="step > 0 && step < steps.length-1" href="#" class="btn btn-link" @click.prevent="goBack">
				<i class="icon-angle-down"></i>
				Back
			</a>
		</div>
		<ol class="onboarding-list">
			<li :class="{'active': step <= 7}">
				<i class="icon-check-full"></i>
				<strong class="title">{{ $t('onboarding-step-about-home') }}</strong>
			</li>
			<li :class="{'active': step > 7 && step < steps.length - 1}">
				<i class="icon-check-full"></i>
				<strong class="title">{{ $t('onboarding-step-about-you') }}</strong>
			</li>
			<li :class="{'active': step == steps.length - 1}">
				<i class="icon-check-full"></i>
				<strong class="title">{{ $t('onboarding-step-thank-you') }}</strong>
			</li>
		</ol>
		<div :class="{'loading': loading}">
			<transition name="slide">
				<component
					:is="onboardingMode"
					:property="propertiesMap[steps[step]]"
					@goNext="goNext"
					@goBack="goBack"
					@skipNext="skipNext"
					@saveProfileInfo="saveProfileInfo"
					@saveProfileLocationTags="saveProfileLocationTags"
					:step="step"
					class="onboarding-step"
					@mounted="setFocus"
				></component>
			</transition>
		</div>
	</main>
</template>

<script>
import CountryLocation from '@/components/Onboarding/CountryLocation.vue';
import MainLocation from '@/components/Onboarding/MainLocation.vue';
import Location from '@/components/Onboarding/Location.vue';
import LocationSelect from '@/components/Onboarding/LocationSelect.vue';
import Style from '@/components/Onboarding/Style.vue';
import Type from '@/components/Onboarding/Type.vue';
import Close2 from '@/components/Onboarding/Close2.vue';
import Investments from '@/components/Onboarding/Investments.vue';
import Budget from '@/components/Onboarding/Budget.vue';
import Name from '@/components/Onboarding/Name.vue';
import Country from '@/components/Onboarding/Country.vue';
import PhoneNumber from '@/components/Onboarding/PhoneNumber.vue';
import Language from '@/components/Onboarding/Language.vue';
import PlanVisit from '@/components/Onboarding/PlanVisit.vue';
import LikelyPurchase from '@/components/Onboarding/LikelyPurchase.vue';
import Benefits from '@/components/Onboarding/Benefits.vue';
import CompanyName from '@/components/Onboarding/CompanyName.vue';
import ThankYou from '@/components/Onboarding/ThankYou.vue';
import { barProperties } from '@/static/staticData.js'

export default {
	name: 'Onboarding',
	components: {
		'country-location': CountryLocation,
		'main-location': MainLocation,
		'location': Location,
		'location-select': LocationSelect,
		'styles': Style,
		'type': Type,
		'close2': Close2,
		'invest': Investments,
		'price': Budget,
		'name': Name,
		'country': Country,
		'phone': PhoneNumber,
		'language': Language,
		'plan_visiting': PlanVisit,
		'likely_purchase': LikelyPurchase,
		'benefits': Benefits,
		'company': CompanyName,
		'confirm': ThankYou
	},
	data() {
		return {
			step: 0,
			steps: ['country-location'],
			onboardingMode: null,
			propertiesMap: {},
			stepMode: 'forward',
			barProperties: barProperties,
			loading: false
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
	},
	methods: {
		skipNext() {
			console.log('skipNext')
			this.step++;

			this.goNext()
		},
		goNext() {
			console.log('goNext')
			this.step++;

			if(this.step == this.steps.length-1) {
				this.savingDefaultsRequest({step: '1000'})
				this.final();
				return;
			} else {
				this.onboardingMode = this.steps[this.step];
				this.cacheStep('step', this.step);
				this.stepMode = 'forward'
			}
			
			if(this.step > this.maxStep) {
				this.maxStep = this.step;
				this.cacheStep('maxStep', this.maxStep);
				this.stepMode = 'forward'
				this.savingDefaultsRequest({step: this.maxStep})
			}
		},
		goBack() {
			this.step--;
			this.cacheStep(this.step);
			this.stepMode = 'back'
			this.onboardingMode = this.steps[this.step];
		},
		resetLocationTags() {
			this.$store.dispatch('resetProfileTagInfo');
			this.profile['tags']
			.forEach((item, i) => {
				this.savingDefaultsRequest({
					tags_remove: item.name || item
				});
			})
		},
		setupOnboardingFlow() {
			switch(this.profile.profile_type) {
				case 1: //buyer
					this.steps = ['country-location', 'main-location', 'location', 'location-select', 'styles', 'type', 'close2', 'invest', 'price', 'name', 'country', 'phone', 'language', 'plan_visiting', 'likely_purchase'];

					this.propertiesMap = {
						first_name: 'first_name',
						last_name: 'last_name',
						languages: 'languages',
						phone: 'phone',
						country: 'country',
						styles: 'buyer_pref_style',
						type: 'buyer_pref_type',
						location: 'location',
						price: 'buyer_budget',
						close2: 'buyer_pref_close',
						plan_visiting: 'buyer_plan_visiting',
						likely_purchase: 'buyer_likely_to_buy',
						invest: 'buyer_pref_invest'
					};
					break;
				case 2: //agent
				case 4:
					this.steps = ['country-location', 'main-location', 'benefits', 'name', 'country', 'language'];

					this.propertiesMap = {
						first_name: 'first_name',
						last_name: 'last_name',
						languages: 'languages',
						phone: 'phone',
						country: 'country',
						location: 'location',
						benefits: 'agent_purpose'
					};
					break;
				case 3: //dev
					this.steps = ['country-location', 'main-location', 'location', 'location-select', 'styles', 'type', 'close2', 'price', 'name', 'company'];

					this.propertiesMap = {
						first_name: 'first_name',
						last_name: 'last_name',
						languages: 'languages',
						phone: 'phone',
						country: 'country',
						styles: 'dev_project_style',
						type: 'dev_project_type',
						price: 'dev_project_starting_price',
						close2: 'dev_project_close',
						company: 'dev_company_name',
						location: 'location'
					};
					break;
				case 5: //seller
					this.steps = ['country-location', 'main-location', 'location', 'location-select', 'styles', 'type', 'close2', 'price', 'name', 'country', 'language'];

					this.propertiesMap = {
						first_name: 'first_name',
						last_name: 'last_name',
						languages: 'languages',
						phone: 'phone',
						country: 'country',
						styles: 'seller_project_style',
						type: 'seller_project_type',
						close2: 'seller_project_close',
						price: 'seller_project_asking_price',
						location: 'location'
					};
					break;
				default:
					break;
			}
			
			this.steps.push('confirm');
		},
		saveProfileLocationTags(tags_add, tags_remove) {
			if(this.step == 0) {
				this.resetLocationTags()
			}
			let param = {};

			if(tags_add) {
				param['tags_add'] = tags_add;
				this.$store.dispatch('addProfileTagInfo', {name: param['tags_add'], type: 7});
			}
			if(tags_remove) {
				param['tags_remove'] = tags_remove;
			}
			
			this.savingDefaultsRequest(param);
		},
		saveProfileInfo(prop, value) {
			let property = this.propertiesMap[prop];

			this.$store.dispatch('setProfileItemInfo', { property: property, value: value });
			this.$store.dispatch('setProfileItemInfo', {
				property: property,
				value: this.parseProperties(this.profile, property)[property]
			});

			let param = {};
			param[property] = this.profile[property];

			if(property == 'buyer_budget') this.validBuyerBudget();
			else if(property == 'seller_project_asking_price') this.validSellerAskingPrice();
			else if(property == 'dev_project_starting_price') this.validDevStartingPrice();
			else this.savingDefaultsRequest(param);
		},
		savingDefaultsRequest(param) {
			this.saving = true;
			param['pk'] = this.profile.id;

			this.$api.post(`/user/update`, param)
			.then(response => {
				this.saving = false;
			})
			.catch(error => {
				console.log(error.response)

				setTimeout(() => {
					this.savingDefaultsRequest(param);
				}, 7500)
			});
		},
		cacheStep(title, value) {
			localStorage.setItem(title, value);
		},
		parseProperties(obj, property) {
			let param = {};
			
			if(this.barProperties.indexOf(property) >= 0) {
				param[property] = `|${obj[property].join('|')}|`
			} else {
				param[property] = obj[property];
			}

			return param
		},
		validBuyerBudget: function() {
			var minPrice = 50000;
			var formatedPrice = this.formatingPrice(this.profile['buyer_budget']);
			
			if(formatedPrice < minPrice) {
				this.$store.dispatch('showWarningFlash', {msg: `${this.$t('common-asking-price-min')} ${minPrice}`});
			} else {
				this.$store.dispatch('setProfileItemInfo', {property: 'buyer_budget', value: formatedPrice});
				this.savingDefaultsRequest({'buyer_budget': this.profile['buyer_budget']});
				this.goNext();
			}
		},
		validSellerAskingPrice: function() {
			var minPrice = 50000;
			var formatedPrice = this.formatingPrice(this.profile['seller_project_asking_price']);
			
			if(formatedPrice < minPrice) {
				this.$store.dispatch('showWarningFlash', {msg: `${this.$t('common-asking-price-min')} ${minPrice}`});
			} else {
				this.$store.dispatch('setProfileItemInfo', {property: 'seller_project_asking_price', value: formatedPrice});
				this.savingDefaultsRequest({'seller_project_asking_price': this.profile['seller_project_asking_price']});
				this.goNext();
			}
		},
		validDevStartingPrice: function() {
			var minPrice = 50000;
			var formatedPrice = this.formatingPrice(this.profile['dev_project_starting_price']);
			
			if(formatedPrice < minPrice) {
				this.$store.dispatch('showWarningFlash', {msg: `${this.$t('common-asking-price-min')} ${minPrice}`});
			} else {
				this.$store.dispatch('setProfileItemInfo', {property: 'dev_project_starting_price', value: formatedPrice});
				this.savingDefaultsRequest({'dev_project_starting_price': this.profile['dev_project_starting_price']});
				this.goNext();
			}
		},
		final() {
			this.loading = true;

			if(!this.saving) this.completedProfile();
			else setTimeout(() => {
				this.final();
			}, 2000)
		},
		completedProfile() {
			this.$api.post('/user/completed', {webpage: window.location.hostname})
			.then((res) => {
				this.loading = false;
				let budget = this.profile.buyer_budget > 3000000 ? 3000000 : this.profile.buyer_budget;
				let formula = budget * this.profile.buyer_likely_to_buy / 10 * 0.05 * 0.1 * this.screenFactor();

				// if(this.profile.buyer_likely_to_buy >= 5 && this.profile.buyer_budget >= 200000 && this.profile.buyer_plan_visiting) {
				// 	this.$gtag.event('purchase', {
				// 		'transaction_id': this.profile.id,
				// 		'value': formula,
				// 		'tax': 0,
				// 		'shipping': 0,
				// 		'currency': this.propertyCurrency,
				// 		'items': [
				// 			{
				// 				'item_id': this.profile.id,
				// 				'item_name': 'new-user-buyer',
				// 				'affiliation': 'new-user-buyer',
				// 				'item_list_id': 'new-user',
				// 				'item_list_name': 'new-user',
				// 				'index': 0,
				// 				'price': formula,
				// 				'quantity': 1
				// 			}
				// 		]
				// 	})
				// } else {
				// 	//We have conversion in purchase event
				// 	this.$gtag.event('conversion', {
				// 		'send_to': 'AW-11033267341/4Q2bCJLdhv0YEI2ZiY0p',
				// 		'transaction_id': this.profile.id,
				// 		'value': formula,
				// 		'currency': this.propertyCurrency
				// 	})
				// }
				
				// if(this.profile.profile_type == 1) {
				// 	//Budget X Likely factor (example 6) use 0.6 x 0.10 X 0.05 (eg. 500000 X 0.6 X 0.1 X 0.05 = 1500)
				// 	let budget2 = this.profile.buyer_budget * this.profile.buyer_likely_to_buy / 10 * 0.1 * 0.05
				// 	budget2 = Number.isInteger(budget2) ? budget2 : parseFloat(budget2.toFixed(2))
				// 	this.ga_track('signup-form-completed-buyer', {budget: budget2})

				// 	if(localStorage.getItem('afl_pid')) {
				// 		this.log_lead('signup-form-completed-buyer', budget2, 'completed')
				// 	}
				// } else {
				// 	this.ga_track(`signup-form-completed-${this.profileTypes[this.profile.profile_type]}`)
				// 	this.log_lead(`signup-form-completed-${this.profileTypes[this.profile.profile_type]}`, 'completed')
				// }
				this.onboardingMode = 'confirm';
				this.$store.dispatch('setProfileItemInfo', { property: 'completed', value: true });
				localStorage.setItem('profileCompleted', true)
				this.$store.dispatch('fetchProfile', this.profile.id);
				localStorage.removeItem('step');
				localStorage.removeItem('maxStep');
			})
		},
		setFocus(parentEl) {
			let focusInput = parentEl.querySelector('input');
			console.log(focusInput)
			if(focusInput) focusInput.focus()
		}
	},
	mounted() {
		if(localStorage.getItem('step')) {
			this.step = parseInt(localStorage.getItem('step'));
		}

		if(localStorage.getItem('maxStep')) {
			this.maxStep = parseInt(localStorage.getItem('maxStep'));
		}
		if(this.profile && this.profile['completed']) {
			this.onboardingMode = 'confirm';
		} else if(this.profile) {
			this.setupOnboardingFlow();
			this.step = this.step >= this.steps.length ? 0 : this.step;
			
			this.onboardingMode = this.steps[this.step];
		}
	},
	watch: {
		profile() {
			if(this.profile['completed']) {
				this.onboardingMode = 'confirm';
			} else {
				this.setupOnboardingFlow();
				this.step = this.step >= this.steps.length ? 0 : this.step;
				
				this.onboardingMode = this.steps[this.step];
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.onboarding-view {
	background: #F8FAFC;
	display: grid;
	grid-template-rows: 120px 1fr;
}

.onboarding-list {
	padding: 20px 0 10px;
	margin: 0;
	counter-reset: counter;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;

	@media(min-width: 1024px) {
		padding: 40px 0 30px;
	}

	[class*="icon-"] {
		font-size: 26px;
		margin-bottom: 5px;
		display: block;
		color: #F3F3F3;
	}

	li {
		list-style: none;
		position: relative;
		padding: 30px 10px;
		text-align: center;

		@media(min-width: 768px) {
			width: 33.332%;
			padding: 0 10px;
		}

		&.active {
			i {
				color: $primary;
			}
		}
	}

	.title {
		font-weight: 400;
		position: relative;

		&:before {
			counter-increment: counter;
			content: counter(counter) '. ';
		}
	}
}

.step-nav {
	position: absolute;
	top: -1px;
	left: 0;
	display: flex;

	.btn {
		min-width: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 14px 5px;
		font-size: 16px;
		border-radius: 0;
		font-weight: 400;

		i {
			font-size: 8px;
			display: block;
			transform: rotate(90deg);
		}
	}
}
</style>
